export enum SortByDateAddedEnum {
  CREATED_AT_ESC = 'createdAt',
  CREATED_AT_DESC = '-createdAt'
}

export enum ActionUser {
  RESET_PASSWORD_USER = 'RESET_PASSWORD_RESET_PASSWORD_USER',
  DEACTIVATE_USER = 'DEACTIVATE_USER',
  ACTIVATE_USER = 'ACTIVATE_USER',
  HIDE_USER = 'HIDE_USER',
  EDIT_USER = 'EDIT_USER'
}

export enum StatusUser {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  HIDDEN = 'HIDDEN'
}
